.ui.competition.button {
  border-radius: 0px !important;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  border-right: 0.1px solid grey;
  border-radius: 10px !important;
  width: 90px;
  height: 60px;
}

/* Scrollable container for the sports buttons */
.sports-tabs {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
}

.sports-scroll-container {
  display: block;
  justify-content: center;
  overflow-x: auto;
  padding-bottom: 5px;
}

.sports-scroll {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
}

/* Style for the sports buttons */
.sport-button {
  background: #f5f5f5;
  padding: 5px 10px !important;
}

.sport-button.active {
  background: #2185d0 !important;
  color: white !important;
}

/* Competition images should not exceed 200px */
.ui.competition.img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
