.game-time {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.game-status {
  padding-top: 24px !important;
  padding-bottom: 2px !important;
  border: none !important;
}

.game-saved-bet-icon {
  margin: 0 0.2rem 0 0 !important;
  border: none !important;
}

.bet-distribution {
  padding-bottom: 10px !important;
  border: none !important;
  line-height: 5px !important;
  margin-top: 1px !important;
}

.no-end-of-segment {
  margin-bottom: 0px !important
}

.logo-bet {
  height: 15px !important;
  width: 15px !important;
  font-size: 15px !important;
}

.bet-distribution-option {
  opacity: 0.45 !important;
  border-left: 1px solid #04476E !important;
}

.multiplier {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
  padding-top: 0 !important;
}