.student-nav {
  width: 40vw;
  background-color: #000066;
  margin: 30px auto;
  text-align: center;
  padding: 10px;
}

.student-nav a {
  color: white;
  font-size: 18px;
}

.student-nav a:hover {
  color: yellow;
}
