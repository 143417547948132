.main-subheader {
    max-width: 300px;
}

.competition-filter-logo {
    margin: 0 4px 0 0 !important;
}

.cookies-banner {
    background-color: white !important;
    color: grey !important;
    border: 1px solid black;
    align-items: center !important;
}

.corporate-main-container {
    margin-top: 400px;
    background: rgba(0, 0, 0, 0.5);
}
  
.content-header {
margin-bottom: 20px;
}
  
@media (min-width: 992px) {
    .content-column {
        vertical-align: flex-start; 
    }
}