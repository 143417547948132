.premier.active {
  background-color: #00ff85 !important;
  color: black;
}

.laliga.active {
  background-color: #EF3340 !important;
  color: white;
}

.bundesliga.active {
  background-color: #d3010c !important;
  color: white;
}

.champions.active {
  background-color: #0053A0 !important;
  color: white;
}

.nba.active {
  background-color: black !important;
  color: white;
}

.mls.active {
  background-color: #151515 !important;
  color: white;
}

.f1.active {
  background-color: #ff1801 !important;
  color: white;
}

.nfl.active {
  background-color: #013369 !important;
  color: white;
}

.ncaa.active {
  background-color: #009cde !important;
  color: white;
}

.euro2024.active {
  background-color: #004ea0 !important;
  color: white;
}

.copaamerica2024.active {
  background-color: #004e85 !important;
  color: white;
}

.seriea.active {
  background-color: #0578FF !important;
  color: white;
}

.liga-f.active {
  background-color: #00256a !important;
  color: white;
}

.kings.active {
  background-color: #f4af23 !important;
  color: black;
}

.queens.active {
  background-color: #6ec3bd !important;
  color: black;
}

.kings-wc.active {
  background-color: black !important;
  color: #f4af23;
}

.cfp.active {
  background-color: #000000 !important;
  color: white;
}

.facup.active {
  background-color: #D81921 !important;
  color: white;
}

.copadelrey.active {
  background-color: #fa0836 !important;
  color: white;
}

.conference.active {
  background-color: #000000 !important;
  color: white;
}

.abiertomx.active {
  background-color: #1b8194 !important;
  color: white;
}

.meridaopen.active {
  background-color: #35a9a0 !important;
  color: black;
}

.tennisdefault.active {
  background-color: #25b396 !important;
  color: black;
}

.madridopen.active {
  background-color: #fa671b !important;
  color: white;
}

.usopen.active {
  background-color: #0d288b !important;
  color: white;
}

.atpfinals.active {
  background-color: #2ba9e0 !important;
  color: white;
}

.afl.active {
  background-color: #e11f31 !important;
  color: white;
}

.australianopen.active {
  background-color: #2390cf !important;
  color: white;
}

.rogerscup.active {
  background-color: #ee2e25 !important;
  color: white;
}

.cincinnatimasters.active {
  background-color: #68bd49 !important;
  color: white;
}