.match {
  border-bottom: 1px solid #E0E2DB !important;
}

.match-competition {
 padding: 0 !important;
 display: flex !important;
 align-items: center;
 justify-content: center;
}

.ui.local.button {
  border-right: 1px solid #04476E;
  border-left: 1px solid #04476E;
}

.ui.away.button {
  border-left: 1px solid #04476E;
}

.ui.match-option.button {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  align-items: center;
  padding: 3px;
  border-radius: 0px !important;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  box-shadow: rgba(255, 255, 255, 0.15) 0px 0px 0px 100px inset;
}

.team-name {
  font-family: 'Noto Sans KR', sans-serif;
  padding-left: 5px !important;
  height: 40px;
}

.match.podium.border {
  border-bottom: 1px solid #04476E;
}

.ui.vs-separator.button:hover, .ui.vs-separator.button.active:hover,
.ui.vs-separator.button, .ui.vs-separator.button.active {
  cursor: default !important;
  pointer-events: none !important;
}

.text-bet {
  font-size: 12px;
}

.dropdown-triplet {
  padding-left: 4px !important;
  padding-right: 0px !important;
  padding-top: 4px !important;
}