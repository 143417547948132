.no-right-padding {
  padding-right: 0 !important;
}

.ui.vertical.menu .item {
  padding: 0.5em 0 !important;
}

.only-right-padding {
  padding-left: 0 !important;
}

.no-borders {
  border: none !important;
}

.ui.selectable.table tr.active.league {
  background: #04476E !important;
  color: white !important;
}

.trends-indent {
  margin-left: 14px !important;
  margin-right: 14px !important;
}
